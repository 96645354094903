<template>
    <div>
        <b-card>
            <b-row style="margin-bottom: -10px;;">
                <b-col md="4">
                    <b-form-group label-for="basicInput">
                        <b-form-select v-model="setBulan" :options="options" size="sm"  @input="loadData()"/>
                    </b-form-group>
                </b-col> 
                <b-col md="4">
                    <b-form-group label-for="basicInput">
                        <b-form-select v-model="setTahun" :options="rsTahun" size="sm"  @input="loadData()" />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-button   block variant="warning" size="sm" @click="loadData()">
                        <feather-icon icon="SearchIcon" size="15" />
                         view
                    </b-button>
                </b-col>
                <b-col md="2">
                    <a :href="baseUrl +'download/rekap_laporan_kelompok/file_data/xx/xx/'+setBulan +'/'+setTahun" target="_blank">
                        <b-button   block variant="primary" size="sm"  >
                        <feather-icon icon="DownloadIcon" size="15" />
                         Download
                    </b-button>
                    </a>
                    
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BFormSelect,
        BButton,
        BCard,
        BFormGroup,
        BFormInput
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BFormSelect,
            BButton,
            BCard,
            BFormGroup,
            BFormInput
        },
        props: {
            rsTahun: {},
            tahun: {},
            bulan: {},
            baseUrl :{}
        },
        computed : {
            setTahun: {
                get: function () {
                    return this.tahun;
                },
                set: function (newValue) {
                    return this.tahun = newValue;
                }
            },
            setBulan: {
                get: function () {
                    return this.bulan;
                },
                set: function (newValue) {
                    return this.bulan = newValue;
                }
            }

        },
        data() {
            return {

                selected: {
                    value: '',
                    text: 'Pilih Bulan'
                },
                options: [{
                        value: '',
                        text: 'Pilih Bulan'
                    },
                    {
                        value: '01',
                        text: 'Januari'
                    },
                    {
                        value: '02',
                        text: 'Februari'
                    },
                    {
                        value: '03',
                        text: 'Maret'
                    },
                    {
                        value: '04',
                        text: 'April'
                    },
                    {
                        value: '05',
                        text: 'Mei'
                    },
                    {
                        value: '06',
                        text: 'Juni'
                    },
                    {
                        value: '07',
                        text: 'Juli'
                    },
                    {
                        value: '08',
                        text: 'Agustus'
                    },
                    {
                        value: '09',
                        text: 'September'
                    },
                    {
                        value: '10',
                        text: 'Oktober'
                    },
                    {
                        value: '11',
                        text: 'November'
                    },
                    {
                        value: '12',
                        text: 'Desember'
                    },

                ],
            }
        },
        emits: ["loadData"],
        methods: {
            loadData() {
                this.$emit('loadData', this.setTahun, this.setBulan);
            }
        }

    }
</script>

<style>

</style>