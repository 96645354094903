<template>
  <div>
    <b-row>
      <b-col md="12"> 
        <card-search :tahun="tahun" :bulan="bulan" :tanggal='tanggal' :rsTahun=rsTahun :baseUrl = baseUrl @loadData=loadData> </card-search>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">  
        <b-card> 
          <list-anggota :rsData="rsData" :tanggal="tanggal" :tgl = tgl :baseUrl = baseUrl></list-anggota>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';
  import {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    BFormGroup,
    BButton

  } from 'bootstrap-vue'
  import cardSearch from './cardSearch.vue';
  import listAnggota from './listAnggota.vue';
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BFormDatepicker,
      BFormGroup,
      BButton,
      cardSearch,
      listAnggota
    },
    mixins: [Base],
    data() {
      return {
        tahun: '',
        bulan: '',
        rsTahun: '',
        rsData: [],
        tanggal : "", 
        tgl :""
      }
    },
    mounted() {
      this.cek_token();
      this.getTahun();
      const self = this;
      const date = new Date();
      self.tahun = date.getFullYear();
      self.bulan = String(date.getMonth() + 1).padStart(2, '0');
      self.tgl =  self.tahun  +'-'+self.bulan ;
      self.load_data();
      self.tgl_now();

    },
    methods: {
      tgl_now() {
        const nm_bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
          'September', 'Oktober', 'November', 'Desember'
        ]
        const ddk = new Date(this.tgl)
        this.tanggal = 'Bulan ' + nm_bulan[ddk.getMonth()] + ', ' + ddk.getFullYear();
      },
      loadData(tahun, bulan) { 
        const self = this;
        self.tahun = tahun;
        self.bulan = bulan;  
        self.tgl =  self.tahun  +'-'+self.bulan ;
        self.tgl_now();
        self.load_data();
      },
      async getTahun() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/kelompok/tahun',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rsTahun = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_data() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/anggota/load_data',
            data: {
              tanggal: self.tahun + '-' + self.bulan
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rsData = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async load_data_(tahun, bulan) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/anggota/load_data',
            data: {
              tanggal:  tahun + '-' +  bulan
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rsData = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>