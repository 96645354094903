<template>
  <div>
    <b-row style="margin-top: -5px;">
      <b-col md="6">
        <h4>
          <b-avatar rounded="" size="25">
            <feather-icon icon="CalendarIcon" size="20" />
          </b-avatar>
          Rekap Presensi {{ tanggal }} 
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>



      <b-col cols="12">
        <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="rsData" :fields="fields"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
          :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(resultdata)="data">
            <b-card class="bgCard">
              <b-row style="margin-left: -30px;;margin-right: -25px;  background: #f7f6f6; padding: 10px;">
                <b-col md="5">
                  <h6><b>Nama : </b><br>{{  data.item.nm_lengkap }}<br><small><b>Jabatan : </b>
                      {{  data.item.nama_jabatan }}</small> <br><b>NIK : </b> {{  data.item.nik }}</h6>
                  <h6><b>Nama Kelompok : </b><br>{{  data.item.nama_kelompok }}<br><small><b>Bidang
                        {{  data.item.nama_bidang }} </b>{{  data.item.keterangan_bidang }}</small></h6>
                </b-col>
                <b-col md="5">
                  <b-alert variant="primary" show>
                    <div class="alert-body">
                      <span><strong>Presensi</strong></span><br>
                      Jam Kerja <b>{{ data.item.jam_masuk_klp }} </b>sampai dengan
                      <b>{{ data.item.jam_pulang_klp }}</b><br>
                      <div>
                        <span> Durasi Kerja <b> {{ data.item.durasi_kerja }} Jam</b></span>
                      </div>
                    </div>
                  </b-alert>
                  <b-alert variant="warning" show>
                    <div class="alert-body">
                      <span><strong>Rekap Presensi</strong></span><br>

                      <div>
                        <span> Jumlah Kehadiran <b> {{ data.item.jm_presensi }} Kali, </b>
                          Ontime <b> {{ parseInt(data.item.jm_presensi)  -  parseInt(data.item.jm_terlambat )}}
                            kali</b>, <br>Terlambat <b>{{ data.item.jm_terlambat }} Kali, </b>
                          Sesuai Durasi jam Kerja <b>{{ data.item.jm_sesuai_durasi }} Kali</b></span>
                      </div>
                    </div>
                  </b-alert>
                </b-col>
                <b-col md="2">
                  <router-link :to="'/laporan_anggota_detail/'+ data.item.hast +data.item.hast +data.item.hast2+'/'+data.item.hast2  + '/'+data.item.id_anggota +'/'+ tgl">
                    <b-button   block variant="warning">
                    <feather-icon icon="ListIcon" size="28"  /> <br> <b>Rincian Presensi</b>
                  </b-button>
                  </router-link>          
                  <a :href="baseUrl +'download/laporan_perkelompok/file_data/'+ data.item.hast +data.item.hast +data.item.hast2+'/'+data.item.hast2  + '/'+data.item.id_anggota +'/'+ tgl" target="_blank" class="mt-1">
                    <b-button   block variant="primary" class="mt-1">
                    <feather-icon icon="DownloadIcon" size="30"  class="mb-1" /> <br> <b>Download</b>
                  </b-button>
                </a>         
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </b-col>

    </b-row>
    <b-row> 
      <b-col cols="6"> 
        {{ totalRows }}
      </b-col>

      <b-col cols="6">
        <b-pagination v-model="currentPage" :total-rows="rsData.length" :per-page="perPage" align="right" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </div>
</template>

<script> 
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BAlert
  } from 'bootstrap-vue'

  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BAlert
    },
    props: {
      rsData: {},
      tanggal: {},
      tgl :{},
      baseUrl : {}
    },
    data() {
      return {
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 100,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [{
          key: 'resultdata',
          label: '',
        }, ],
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
      },
    },
    mounted() {  
      this.totalRows = this.rsData.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    },
  }
</script>
<style lang="scss">
  .bg {
    background: #a3d3ff4d;
  }
</style>